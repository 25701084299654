<template>
  <!-- <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  > -->
  <!-- <template #overlay>
    <div class="text-center">
      <b-icon-controller
        font-scale="3"
        animation="cylon"
      />
      <p id="cancel-label">
        กรุณารอสักครู่...
      </p>
    </div>
  </template> -->
  <div class="container fn text-white mt-2">
    <div>
      <div>
        <h4>
          <router-link :to="{ name: 'home' }">
            <i class="fas fa-chevron-circle-left fa-lg" />
          </router-link>
          เชิญเพื่อนรับเครดิต
        </h4>
      </div>
      <div class="depo">
        <div>
          <h3>ข้อมูลสำหรับชวนเพื่อน</h3>
        </div>
        <div class="bg p-1 mb-2">
          <b-row>
            <b-col cols="8">
              <div>
                <span class="text-gray">รหัสชวนเพื่อน</span>
                <p class="highlight">
                  {{ invite_code }}
                </p>

                <b-button
                  variant="success"
                  class="d-none d-md-flex"
                  @click="GetReward()"
                >
                  <i class="fad fa-sack-dollar" /> รับเงินชวนเพื่อน
                  {{
                    Commas(
                      friendData.reduce(
                        (acc, ele) =>
                          acc +
                          (ele.List.length > 0
                            ? ele.List.reduce(
                              (acc2, ListEle) => acc2 + ListEle[ele.ListName],
                              0,
                            )
                            : 0),
                        0,
                      ),
                    )
                  }}
                  บาท
                </b-button>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="d-flex justify-content-end">
                <qrcode-vue
                  :value="`https://www.lottotheone.com/register?ref=${invite_code}`"
                  size="100"
                  level="M"
                />
              </div>
            </b-col>
          </b-row>
          <b-button
            variant="success"
            block
            class="d-block d-md-none"
            @click="GetReward()"
          >
            <i class="fad fa-sack-dollar" /> รับเงินชวนเพื่อน
            {{
              Commas(
                friendData.reduce(
                  (acc, ele) =>
                    acc +
                    (ele.List.length > 0
                      ? ele.List.reduce(
                        (acc2, ListEle) => acc2 + ListEle[ele.ListName],
                        0,
                      )
                      : 0),
                  0,
                ),
              )
            }}
            บาท
          </b-button>
        </div>
        <div class="text-center">
          <h4>ลิงก์ชวนเพื่อน</h4>
          <small
            class="text-gray"
          >https://www.lottotheone.com/register?ref={{ invite_code }}</small>
          <br>
          <button
            v-clipboard:copy="message"
            class="copy"
            @click="showToast1()"
          >
            <i class="fad fa-copy" />
            คัดลอกลิ้งค์
          </button>
          <!-- Sharingbutton Line -->

          <div>
            <a
              class="resp-sharing-button__link"
              :href="`https://social-plugins.line.me/lineit/share?url=https://www.lottotheone.com/register?ref=${invite_code}`"
              target="_blank"
              rel="noopener"
              aria-label=""
            >
              <div
                class="resp-sharing-button"
                style="margin-right: -20px"
              >
                <div aria-hidden="true">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="54"
                    height="54"
                    viewBox="0 0 48 48"
                    style="fill: #000000"
                  >
                    <path
                      fill="#00c300"
                      d="M12.5,42h23c3.59,0,6.5-2.91,6.5-6.5v-23C42,8.91,39.09,6,35.5,6h-23C8.91,6,6,8.91,6,12.5v23C6,39.09,8.91,42,12.5,42z"
                    />
                    <path
                      fill="#fff"
                      d="M37.113,22.417c0-5.865-5.88-10.637-13.107-10.637s-13.108,4.772-13.108,10.637c0,5.258,4.663,9.662,10.962,10.495c0.427,0.092,1.008,0.282,1.155,0.646c0.132,0.331,0.086,0.85,0.042,1.185c0,0-0.153,0.925-0.187,1.122c-0.057,0.331-0.263,1.296,1.135,0.707c1.399-0.589,7.548-4.445,10.298-7.611h-0.001C36.203,26.879,37.113,24.764,37.113,22.417z M18.875,25.907h-2.604c-0.379,0-0.687-0.308-0.687-0.688V20.01c0-0.379,0.308-0.687,0.687-0.687c0.379,0,0.687,0.308,0.687,0.687v4.521h1.917c0.379,0,0.687,0.308,0.687,0.687C19.562,25.598,19.254,25.907,18.875,25.907z M21.568,25.219c0,0.379-0.308,0.688-0.687,0.688s-0.687-0.308-0.687-0.688V20.01c0-0.379,0.308-0.687,0.687-0.687s0.687,0.308,0.687,0.687V25.219z M27.838,25.219c0,0.297-0.188,0.559-0.47,0.652c-0.071,0.024-0.145,0.036-0.218,0.036c-0.215,0-0.42-0.103-0.549-0.275l-2.669-3.635v3.222c0,0.379-0.308,0.688-0.688,0.688c-0.379,0-0.688-0.308-0.688-0.688V20.01c0-0.296,0.189-0.558,0.47-0.652c0.071-0.024,0.144-0.035,0.218-0.035c0.214,0,0.42,0.103,0.549,0.275l2.67,3.635V20.01c0-0.379,0.309-0.687,0.688-0.687c0.379,0,0.687,0.308,0.687,0.687V25.219z M32.052,21.927c0.379,0,0.688,0.308,0.688,0.688c0,0.379-0.308,0.687-0.688,0.687h-1.917v1.23h1.917c0.379,0,0.688,0.308,0.688,0.687c0,0.379-0.309,0.688-0.688,0.688h-2.604c-0.378,0-0.687-0.308-0.687-0.688v-2.603c0-0.001,0-0.001,0-0.001c0,0,0-0.001,0-0.001v-2.601c0-0.001,0-0.001,0-0.002c0-0.379,0.308-0.687,0.687-0.687h2.604c0.379,0,0.688,0.308,0.688,0.687s-0.308,0.687-0.688,0.687h-1.917v1.23H32.052z"
                    />
                  </svg>
                </div>
              </div>
            </a>
            <!-- Sharingbutton Facebook -->
            <a
              class="resp-sharing-button__link"
              :href="`https://facebook.com/sharer/sharer.php?u=https://www.lottotheone.com/register?ref=${invite_code}`"
              target="_blank"
              rel="noopener"
              aria-label=""
            >
              <div
                class="
                  resp-sharing-button
                  resp-sharing-button--facebook
                  resp-sharing-button--small
                "
              >
                <div
                  aria-hidden="true"
                  class="
                    resp-sharing-button__icon resp-sharing-button__icon--solid
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"
                    />
                  </svg>
                </div>
              </div>
            </a>

            <!-- Sharingbutton Twitter -->
            <a
              class="resp-sharing-button__link"
              :href="`https://twitter.com/intent/tweet/?text=%E0%B8%A1%E0%B8%B2%E0%B9%80%E0%B8%A5%E0%B9%88%E0%B8%99%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%AA%E0%B8%B4%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%9C%E0%B8%B4%E0%B8%94%E0%B8%AB%E0%B8%A7%E0%B8%B1%E0%B8%87%E0%B9%81%E0%B8%99%E0%B9%88%E0%B8%99%E0%B8%AD%E0%B8%99&amp;url=https://www.lottotheone.com/register?ref=${invite_code}`"
              target="_blank"
              rel="noopener"
              aria-label=""
            >
              <div
                class="
                  resp-sharing-button
                  resp-sharing-button--twitter
                  resp-sharing-button--small
                "
              >
                <div
                  aria-hidden="true"
                  class="
                    resp-sharing-button__icon resp-sharing-button__icon--solid
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"
                    />
                  </svg>
                </div>
              </div>
            </a>

            <!-- Sharingbutton Telegram -->
            <a
              class="resp-sharing-button__link"
              :href="`https://telegram.me/share/url?text=%E0%B8%A1%E0%B8%B2%E0%B9%80%E0%B8%A5%E0%B9%88%E0%B8%99%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B9%80%E0%B8%A3%E0%B8%B2%E0%B8%AA%E0%B8%B4%E0%B9%84%E0%B8%A1%E0%B9%88%E0%B8%9C%E0%B8%B4%E0%B8%94%E0%B8%AB%E0%B8%A7%E0%B8%B1%E0%B8%87%E0%B9%81%E0%B8%99%E0%B9%88%E0%B8%99%E0%B8%AD%E0%B8%99&amp;url=https://www.lottotheone.com/register?ref=${invite_code}`"
              target="_blank"
              rel="noopener"
              aria-label=""
            >
              <div
                class="
                  resp-sharing-button
                  resp-sharing-button--telegram
                  resp-sharing-button--small
                "
              >
                <div
                  aria-hidden="true"
                  class="
                    resp-sharing-button__icon resp-sharing-button__icon--solid
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M.707 8.475C.275 8.64 0 9.508 0 9.508s.284.867.718 1.03l5.09 1.897 1.986 6.38a1.102 1.102 0 0 0 1.75.527l2.96-2.41a.405.405 0 0 1 .494-.013l5.34 3.87a1.1 1.1 0 0 0 1.046.135 1.1 1.1 0 0 0 .682-.803l3.91-18.795A1.102 1.102 0 0 0 22.5.075L.706 8.475z"
                    />
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
        <hr>
        <h4>
          ลำดับขั้น ชวนเพื่อน
          <i
            style="color: #ffc800"
            class="fad fa-redo-alt"
            @click="getfriend()"
          />
        </h4>

        <hr>
        <b-row class="neon text-center mt-1 mb-1">
          <b-col
            v-for="(item, index) in friendData"
            :key="item.ListName"
            md="3"
            class="mt-1"
          >
            <div class="card-level-head-text">
              <h5 class="font-weight-bolder">
                <i
                  class="fas fa-crown"
                  style="
                    color: #fbc02d;
                    -webkit-text-stroke: 0.8px rgb(255, 71, 0);
                  "
                />
                {{ item.ListName }}
              </h5>
            </div>
            <div class="card-level-head">
              <div class="pt-1 pb-1">
                <b-row>
                  <b-col
                    cols="6"
                    class="d-flex justify-content-start"
                  >
                    <div class="person">
                      <i class="fad fa-users" />
                      {{ Commas(item.List.length) }} คน
                    </div>
                  </b-col>
                  <b-col
                    cols="6"
                    class="d-flex justify-content-end"
                  >
                    <div class="balance">
                      {{
                        Commas(
                          item.List.reduce(
                            (acc, ele) => acc + ele[item.ListName],
                            0,
                          ),
                        )
                      }}
                      บาท
                    </div></b-col>
                </b-row>

                <div v-if="item.List.length > 0">
                  <div
                    v-for="ItemList in Paginate(
                      item.List,
                      5,
                      currentPage[index],
                    )"
                    :key="ItemList.id"
                    class="bage"
                  >
                    <b-row>
                      <b-col cols="6">
                        {{ ItemList.tel.substring(0, 6) }}xxxx
                      </b-col>
                      <b-col cols="6">
                        {{ Commas(ItemList[item.ListName]) }} บาท
                      </b-col>
                    </b-row>
                  </div>
                </div>

                <div
                  v-if="item.List.length > 0"
                  class="demo-spacing-0 mt-1"
                >
                  <b-pagination
                    v-model="currentPage[index]"
                    :total-rows="item.List.length"
                    per-page="5"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                    class="pagination-warning"
                    limit="10"
                    align="center"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>
      </div>
    </div>
  </div>
  <!-- </b-overlay> -->
</template>
<script>
import {
  // BInputGroup,
  // BFormInput,
  BRow,
  BCol,
  BButton,
  BPagination,
  // BOverlay,
  // BIconController,
  // BBadge,
} from 'bootstrap-vue'
// import moment from 'moment-timezone'
import QrcodeVue from 'qrcode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import VueClipboard from 'vue-clipboard2'

export default {
  components: {
    // BInputGroup,
    // BFormInput,
    BRow,
    BCol,
    BButton,

    // BOverlay,
    // BIconController,
    QrcodeVue,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BPagination,
    // BBadge,
  },
  data() {
    return {
      rows: 1,
      currentPage: [1, 1, 1, 1, 1, 1, 1, 1],
      show: true,
      message: '',
      balance: '',
      amount: '',
      friendData: [
        {
          ListName: 'Level1',
          List: [],
        },
        {
          ListName: 'Level2',
          List: [],
        },
        {
          ListName: 'Level3',
          List: [],
        },
        {
          ListName: 'Level4',
          List: [],
        },
        {
          ListName: 'Level5',
          List: [],
        },
        {
          ListName: 'Level6',
          List: [],
        },
        {
          ListName: 'Level7',
          List: [],
        },
        {
          ListName: 'Level8',
          List: [],
        },
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
      User: '',
      invite_code: '',
    }
  },
  mounted() {
    this.invite_code = this.userData.invite_code
    this.getfriend()
  },
  methods: {
    showToast1() {
      this.$toast.success('คัดลอกลิ้งสำเร็จ', {
        position: 'top-right',
        timeout: 3475,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'fad fa-paste',
        rtl: false,
      })
      this.message = `https://www.lottotheone.com/register?ref=${this.invite_code}`
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'คัดลอกลิ้งค์สำเร็จ',
          icon: 'CopyIcon',
          text: '👋 คัดลอกข้อความเรียบร้อยแล้วนะคะ',
          variant,
        },
      })
    },
    // getUser(userData) {
    //   this.show = true
    //   this.$http
    //     .get(`https://wapi.hitler168.com/api/users/show/${userData.userID}`)
    //     .then(response => {
    //       // console.log(response.data)
    //       this.show = false
    //       this.User = response.data
    //       this.getfriend(response.data.invite_code)
    //     })
    //     .catch(error => console.log(error))
    // },
    getfriend() {
      this.show = true
      this.$http
        .get('https://api.ma5lotto.com/api/invite/friendlist')
        .then(response => {
          this.show = false
          this.friendData = response.data
        })
        .catch(error => console.log(error))
    },
    GetReward() {
      this.show = true
      this.$http
        .get('https://api.ma5lotto.com/api/invite/getreward')
        .then(response => {
          this.show = false
          this.getfriend()
          this.Success(`ยอดจำนวนเงินทั้งหมด ${response.data.mes} บาท`)
        })
        .catch(error => {
          this.SwalError(error.response.data.mes)
        })
    },
    Paginate(array, PageSize, PageNumber) {
      return array.slice((PageNumber - 1) * PageSize, PageNumber * PageSize)
    },
    ShareLine() {
      // new media_line_me.LineButton({
      //   pc: false,
      //   lang: 'en',
      //   type: 'a',
      //   text: '',
      //   withUrl: true,
      // })
      // const url = `https://social-plugins.line.me/lineit/share?url=https://www.lottotheone.com/register?ref=${this.invite_code}`
      // window.open(url, '_blank').focus()
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: 'ทำรายการเรียบร้อยค่ะ',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'warning',
        title: '<h3 style="color:#000">ขออภัยค่ะ!<span>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
/* .container {
  max-width: 540px;
} */
/* asdaas */
.balance-cl {
  background-color: #ffc800;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 1.8rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.copy {
  margin-top: 15px;
  border: #490000;
  padding: 10px 25px;
  box-shadow: rgba(99, 99, 99, 0.637) 0px 5px 20px;
  background-color: #d60000;
  border-radius: 38px;
  color: #fff;
}
small {
  font-size: 1rem;
  color: #000;
}
.depo {
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
  background-color: #fff;
}
.bg {
  background-image: url(/images/head2.svg);
  background-position: center center;
  border-radius: unset;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  z-index: unset;
}
.card-level-head {
  background: rgb(238, 125, 132);
  background: linear-gradient(
    158deg,
    rgba(238, 125, 132, 1) 0%,
    rgba(155, 12, 38, 1) 100%
  );
  border-radius: 12px;
  margin-top: -30px;
}
.card-level-head-text {
  margin: auto;
  background-color: #dedede;
  height: 70px;
  width: 70px;
  border: 5px solid #c6c6c8;
  color: #000;
  border-radius: 50%;
  box-shadow: #464646 0px 5px 5px;
  margin-top: -10px;
  z-index: 1;
  position: relative;
}
.bage {
  margin-top: 5px;
  background-color: #1a57b3c5;
  border-bottom: 1px solid #c6c6c8;
  padding: 10px;
}
.bs {
  margin: auto;
}

.balance {
  background-color: #1a57b3;
  border-radius: 25px 0px 0px 25px;
  padding: 5px;
  box-sizing: border-box;
  width: 90px;
  height: 35px;
  color: rgb(255, 255, 255);
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.342) 0px 5px 5px;
}

.person {
  background-color: #1a57b3;
  border-radius: 0px 25px 25px 0px;
  padding: 5px;
  box-sizing: border-box;
  width: 90px;
  height: 35px;
  color: rgb(255, 255, 255);
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.342) 0px 5px 5px;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--telegram {
  background-color: #54a9eb;
}

.resp-sharing-button--telegram:hover {
  background-color: #4b97d1;
}

/* asdasd */
</style>
